import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Header.scss";

const Header = props => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 85);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="Header" data-testid="Header">
      <header className={`header ${isScrolled ? "on-scroll" : ""}`} id="header" style={{position: props.stickyTop ? "fixed" : "relative"}}>
        <nav className="navbar container">
          <a href="#" className="brand">
            <img src={props.logo || "./logo.jpg"} alt="Logo" />
          </a>
          <div
            className={`burger ${isMenuActive ? "is-active" : ""}`}
            id="burger"
            onClick={() => setIsMenuActive(prev => !prev)}
          >
            <span className="burger-line" />
            <span className="burger-line" />
            <span className="burger-line" />
          </div>
          <div className={`menu ${isMenuActive ? "is-active" : ""}`} id="menu">
            <ul className="menu-inner">
              {props.menuItems.map((item, index) =>
                <li className="menu-item" key={index}>
                  <a href="#" className="menu-link">
                    {item}
                  </a>
                </li>
              )}
            </ul>
          </div>
          {props.showLogin.showLogin &&
            <a
              href={props.showLogin.path}
              className="menu-block"
            >
              Login
            </a>}
            {props.showSignup.showSignup &&
            <a
            href={props.showSignup.path}
              className="menu-block"
            >
              Start Free
            </a>}
        </nav>
      </header>
    </div>
  );
};

Header.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string),
  logo: PropTypes.string,
  showLogin: PropTypes.bool,
  showSignup: PropTypes.bool,
  stickyTop: PropTypes.bool
};

export default Header;
