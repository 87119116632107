import React, {useState, useEffect} from 'react';
import './HeaderDark1.scss';

const HeaderDark1 = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Dynamic menu links
  const menuItems = ["Home", "Wishlist", "Support"];

  // Toggle menu on burger click
  const handleBurgerClick = () => {
    setIsMenuActive((prev) => !prev);
  };

  // Close menu on menu link click
  useEffect(() => {
    const handleLinkClick = () => setIsMenuActive(false);

    const menuLinks = document.querySelectorAll(".menu-link");
    menuLinks.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      menuLinks.forEach((link) => link.removeEventListener("click", handleLinkClick));
    };
  }, []);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 85);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuActive(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="HeaderDark1" data-testid="HeaderDark1">
      <header className={`header ${isScrolled ? "on-scroll" : ""}`} id="header">
        <nav className="navbar container">
          <a href="#" className="brand">
            Brand
          </a>
          <div
            className={`burger ${isMenuActive ? "is-active" : ""}`}
            id="burger"
            onClick={handleBurgerClick}
          >
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <div className={`menu ${isMenuActive ? "is-active" : ""}`} id="menu">
            <ul className="menu-inner">
              {menuItems.map((item, index) => (
                <li className="menu-item" key={index}>
                  <a href="#" className="menu-link">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <a href="#" className="menu-block">
            Login
          </a>
        </nav>
      </header>
    </div>
  );
};

export default HeaderDark1;
