import React from 'react';
import PropTypes from 'prop-types';
import './Home.scss';
import Box from '@mui/material/Box';
import { useAuth } from '../../services/authContext';

const Home = ({ components = [] }) => {
  const { loginStatus } = useAuth();

  return (
    <Box
      className='perfect-center'
      sx={{
        background: !loginStatus.token ? '#ffffff' : '',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom left'
      }}
      data-testid="Home"
    >
      <div className='mainlogo perfect-center'>
        {/* Render only the components specified in `routeConfig` */}
        {components.map((Component, index) => (
          <React.Fragment key={index}>{Component}</React.Fragment>
        ))}
      </div>
    </Box>
  );
};

// ✅ PropTypes validation
Home.propTypes = {
  components: PropTypes.arrayOf(PropTypes.element)
};

export default Home;
