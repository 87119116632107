import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#5c97c3' },
    dark: { main: '#111111' },
    background: { default: '#081727', paper: '#081727', warning: '#f5c44e' }
  }
});

const Layout = ({ components = [] }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        data-testid="layout"
        sx={{
          background: "#eeeeee",
          width: "100%",
          overflowX: "hidden",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column"
        }}
      >
        {/* Render all components dynamically */}
        {components.map((Component, index) => (
          <React.Fragment key={index}>{Component}</React.Fragment>
        ))}

        {/* Page content */}
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

// ✅ PropTypes validation
Layout.propTypes = {
  components: PropTypes.arrayOf(PropTypes.element)
};

export default Layout;
