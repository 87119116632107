import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Divider,
} from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { client_id } from '../../constants/app.constant';
import { LoginApi } from '../../services/apis';
import { useAuth } from '../../services/authContext';
import CryptoJS from 'crypto-js'
import secureLocalStorage from 'react-secure-storage'

const Profile = () => {
  const auth = useAuth() || {};
  const { loginStatus = {}, saveLoginStatus } = auth;
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function generateRandomString (length) {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return result
  }

  const handleManualLogin = async (e) => {
    e.preventDefault();
    try {
      setError('');
      const payload = { email, password };
      const response = await LoginApi(payload);
      console.log("API Response:", response);
      const efefrg4rrrg323ffgfssw3e3ddadfw23 = generateRandomString(256) // Adjust length as needed
      secureLocalStorage.setItem('key', efefrg4rrrg323ffgfssw3e3ddadfw23)
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(response),
        secureLocalStorage.getItem('key') // Replace with a secure key
      ).toString() // Encrypt the response data

      localStorage.setItem('loginCred', encryptedData) // Store encrypted data
      window.location.reload();
    } catch (err) {
      setError('Invalid email or password. Please try again.');
      console.error('Manual Login Error:', err);
    }
  };
  

  const handleGoogleLogin = async (googleResponse) => {
    try {
      console.log("Google Response:", googleResponse);
  
      setError('');
  
      if (!googleResponse || !googleResponse.credential) {
        console.error("Google login response is missing 'credential'.", googleResponse);
        setError('Google login failed. No credential received.');
        return;
      }
  
      const payload = { token: googleResponse.credential };
      console.log("Sending Payload to API:", payload);
  
      const response = await LoginApi(payload);
      console.log("API Response:", response);
      const efefrg4rrrg323ffgfssw3e3ddadfw23 = generateRandomString(256) // Adjust length as needed
      secureLocalStorage.setItem('key', efefrg4rrrg323ffgfssw3e3ddadfw23)
      const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(response),
        secureLocalStorage.getItem('key') // Replace with a secure key
      ).toString() // Encrypt the response data

      localStorage.setItem('loginCred', encryptedData) // Store encrypted data
      window.location.reload();
      if (!response || !response.token) {
        console.error("Login API response is invalid:", response);
        setError('Login failed. Invalid response from server.');
        return;
      }
  
      saveLoginStatus(response);
    } catch (err) {
      console.error('Google Login Error:', err);
      setError('Login failed. Please try again.');
    }
  };
  

  return (
    <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'var(--colors-blue-800)', padding: '20px'}} data-testid="Profile">
      {!loginStatus?.token ? (
        <Card sx={{ width: '100%', maxWidth: '400px', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff' }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant="h5" sx={{ marginBottom: '16px' }}>Login</Typography>

            <form onSubmit={handleManualLogin}>
              <TextField label="Email" type="email" variant="outlined" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} sx={{ marginBottom: '16px' }} />
              <TextField label="Password" type="password" variant="outlined" fullWidth required value={password} onChange={(e) => setPassword(e.target.value)} sx={{ marginBottom: '16px' }} />
              <Button type="submit" variant="contained" color="primary" fullWidth sx={{ marginBottom: '8px' }}>Submit</Button>
            </form>

            <Typography variant="body2" sx={{ marginBottom: '16px' }}>Don&apos;t have an account? <a href="/register">Create new account</a></Typography>

            <Divider sx={{ margin: '16px 0' }}>OR</Divider>

            <GoogleOAuthProvider clientId={client_id}>
              <GoogleLogin onSuccess={handleGoogleLogin} onError={() => setError('Login failed. Please try again.')} />
            </GoogleOAuthProvider>

            {error && <Typography variant="body2" color="error" sx={{ marginTop: '8px' }}>{error}</Typography>}
          </CardContent>
        </Card>
      ) : (
        <Typography variant="h6">You are logged in!</Typography>
      )}
    </Box>
  );
};

export default Profile;
