import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';  // Import PropTypes
import CryptoJS from 'crypto-js';
import { efefrg4rrrg323ffgfssw3e3ddadfw23 } from '../components/componentConst';

const AuthContext = createContext();
const SECRET_KEY = efefrg4rrrg323ffgfssw3e3ddadfw23;

// Helper functions for encryption and decryption
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error('Error decrypting data:', error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState(() => {
    const encryptedCreds = localStorage.getItem('loginCred');
    if (encryptedCreds) {
      const decryptedData = decryptData(encryptedCreds);
      return decryptedData || {}; // Return decrypted data or fallback to an empty object
    }
    return {};
  });

  const saveLoginStatus = (data) => {
    console.log("Saving login status:", data); // Debugging
    const encryptedData = encryptData(data);
    console.log("get enc data", encryptedData);
    localStorage.setItem('loginCred', encryptedData);
    setLoginStatus(data);
  };

  const clearLoginStatus = () => {
    localStorage.removeItem('loginCred');
    setLoginStatus({});
  };

  return (
    <AuthContext.Provider value={{ loginStatus, saveLoginStatus, clearLoginStatus }}>
      {children}
    </AuthContext.Provider>
  );
};

// Add PropTypes validation
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);
