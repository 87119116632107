import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import './StickeyFullMenu.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const StickeyFullMenu = (props) => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Toggle menu on burger click
  const handleBurgerClick = () => {
    setIsMenuActive((prev) => !prev);
  };

  // Close menu on menu link click
  useEffect(() => {
    const handleLinkClick = () => setIsMenuActive(false);
    const menuLinks = document.querySelectorAll(".menu-link");
    menuLinks.forEach((link) => link.addEventListener("click", handleLinkClick));

    return () => {
      menuLinks.forEach((link) => link.removeEventListener("click", handleLinkClick));
    };
  }, []);

  // Handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 85);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    console.log(props);
  }, [props.menuItems]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuActive(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="StickeyFullMenu" data-testid="StickeyFullMenu">
      <header className={`header ${isScrolled ? "on-scroll" : ""}`} id="header" style={{position: "fixed"}}>
        <nav className="navbar container">
          <a href="#" className="brand">
            <img src={props.logo || "./logo.jpg"} alt="Logo" />
          </a>
          <div
            className={`burger ${isMenuActive ? "is-active" : ""}`}
            id="burger"
            onClick={handleBurgerClick}
          >
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <div className={`menu ${isMenuActive ? "is-active" : ""}`} id="menu">
            <ul className="menu-inner">
              {(props.menuItems).map((item, index) => (
                <li className="menu-item" key={index}>
                  <a href="#" className="menu-link">
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {props.showLogin ? 
            <>
              <a href="#" className="menu-block">
                Login
              </a>
            </> :
            null
          }
          
        </nav>
        <div
          className="search__container SearchBox p-4"
          style={{
            width: "100%",
            zIndex: 1000,
            transition: "all 0.3s ease"
          }}
        >
          <div className="container row">
            <div className="col-10">
              <input className="search__input" type="text" placeholder="Search" />
            </div>
            <div className="col-2">
              <button className="menu-block">
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

StickeyFullMenu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.string),
  logo: PropTypes.string,
  showLogin: PropTypes.bool
};

StickeyFullMenu.defaultProps = {};

export default StickeyFullMenu;
