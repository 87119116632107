import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import "./SearchBox.scss";

const SearchBox = (props) => {
  const [isSticky, setIsSticky] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      // Detect scroll position
      if (props.stickyTop && window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="search__container SearchBox p-4"
      data-testid="SearchBox"
      style={{
        position: isSticky ? "fixed" : "relative", // ✅ Switch dynamically
        top: isSticky ? "0px" : "unset",
        width: "100%",
        zIndex: 1000,
        transition: "all 0.3s ease"
      }}
    >
      <div className="container row">
        <div className="col-10">
          <input className="search__input" type="text" placeholder="Search" />
        </div>
        <div className="col-2">
          <button className="menu-block">
            <FontAwesomeIcon icon={faShoppingCart} />
          </button>
        </div>
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  stickyTop: PropTypes.bool
};

export default SearchBox;
