import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Layout from "./components/Layout/Layout";
import LayoutWithBackButton from "./components/LayoutWithBackButton/LayoutWithBackButton";
import Initial from "./components/Initial/Initial";
import AdminDashBoard from "./components/AdminDashBoard/AdminDashBoard";
import HeaderDark1 from "./components/HeaderDark1/HeaderDark1";
import SearchBox from "./components/SearchBox/SearchBox";
import MainSection from "./components/MainSection/MainSection";
import SubHero from "./components/SubHero/SubHero";
import StickeyFullMenu from "./components/StickeyFullMenu/StickeyFullMenu";
import Profile from "./components/Profile/Profile";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "bootstrap/dist/css/bootstrap.min.css";
import Register from "./components/Profile/Register";

const theme = createTheme({
  typography: {
    fontFamily: "CustomFont, Arial, sans-serif",
    h1: { fontWeight: 700 },
    h2: { fontWeight: 600 },
    body1: { fontWeight: 400 },
    body2: { fontWeight: 300 },
    italic: { fontWeight: 400, fontStyle: "italic" }
  }
});

// ✅ Define all available components
const componentMap = {
  Header: props => <Header {...props} />,
  Footer: props => <Footer {...props} />,
  LayoutWithBackButton: props => <LayoutWithBackButton {...props} />,
  Initial: props => <Initial {...props} />,
  AdminDashBoard: props => <AdminDashBoard {...props} />,
  HeaderDark1: props => <HeaderDark1 {...props} />,
  SearchBox: props => <SearchBox {...props} />,
  MainSection: props => <MainSection {...props} />,
  SubHero: props => <SubHero {...props} />,
  StickeyFullMenu: props => <StickeyFullMenu {...props} />,
  Profile: props => <Profile {...props} />,
  Register: props => <Register {...props} />
};

// ✅ Define routes and components dynamically inside `App.js`
const routeConfig = [
  {
    path: "/",
    layoutComponents: [
      {
        name: "Header",
        props: {
          menuItems: ["Home", "abc"],
          showLogin: { showLogin: true, path: "/profile" },
          showSignup: { showSignup: true, path: "/register" },
          stickyTop: true
        }
      },
      { name: "SearchBox", props: { stickyTop: false } },
      {
        name: "MainSection",
        props: {
          socialMedia: [
            { icon: "bxl-facebook", url: "#" },
            { icon: "bxl-instagram", url: "https://instagram.com" },
            { icon: "bxl-twitter", url: "#" },
            { icon: "bxl-youtube", url: "#" }
          ],
          bannerContent: {
            image:
              "https://dukaan.b-cdn.net/1440x1440/webp/upload_file_service/f83eac56-a037-4fdb-a96a-8f171287c749/image.png",
            title: "Hi this is Xtute",
            description:
              "dsccsd store and drive more customer engagement and sales.",
            buttonText: { buttonText: "Get Started", url: "https://abc.xyz" }
          }
        }
      },
      { name: "Footer", props: { year: 2025, companyName: "Lactae" } }
    ],
    pageComponents: [
      {
        name: "LayoutWithBackButton",
        props: {
          backButtonText: "Dashboard Home",
          backButtonLink: "/admin/home"
        }
      }
    ]
  },
  {
    path: "/profile",
    layoutComponents: [
      {
        name: "Header",
        props: {
          menuItems: ["Home", "abc"],
          showLogin: { showLogin: true, path: "/profile" },
          showSignup: { showSignup: true, path: "/register" },
          stickyTop: true
        }
      },
      { name: "Profile", props: { year: 2025, companyName: "Lactae" } },
      { name: "Footer", props: { year: 2025, companyName: "Lactae" } }
    ],
    pageComponents: [
      {
        name: "LayoutWithBackButton",
        props: {
          backButtonText: "Dashboard Home",
          backButtonLink: "/admin/home"
        }
      }
    ]
  },
  {
    path: "/register",
    layoutComponents: [
      {
        name: "Header",
        props: {
          menuItems: ["Home", "abc"],
          showLogin: { showLogin: true, path: "/profile" },
          showSignup: { showSignup: true, path: "/register" },
          stickyTop: true
        }
      },
      { name: "Register", props: { year: 2025, companyName: "Lactae" } },
      { name: "Footer", props: { year: 2025, companyName: "Lactae" } }
    ],
    pageComponents: [
      {
        name: "LayoutWithBackButton",
        props: {
          backButtonText: "Dashboard Home",
          backButtonLink: "/admin/home"
        }
      }
    ]
  }
];

// ✅ Function to create component dynamically
const createComponent = ({ name, props }, index) =>
  React.createElement(componentMap[name], { key: index, ...props });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div data-testid="App">
          <Routes>
            {routeConfig.map((route, index) =>
              <Route
                key={index}
                path={route.path}
                element={
                  <Layout
                    components={route.layoutComponents.map(createComponent)}
                  />
                }
              >
                <Route
                  index
                  element={
                    <Home
                      components={route.pageComponents.map(createComponent)}
                    />
                  }
                />
              </Route>
            )}
          </Routes>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
