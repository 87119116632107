import React from "react";
import "./Footer.scss"; // Ensure you have corresponding styles

const Footer = () => {
  // Random bubbles generation
  const bubbles = Array.from({ length: 128 }, () => ({
    size: `${2 + Math.random() * 4}rem`,
    distance: `${6 + Math.random() * 4}rem`,
    position: `${-5 + Math.random() * 110}%`,
    time: `${2 + Math.random() * 2}s`,
    delay: `${-1 * (2 + Math.random() * 2)}s`,
  }));

  // Footer links data
  const footerLinks = [
    {
      title: "Terms : ",
      links: ["Terms and Conditions", "Privacy Policy", "Return Policy"],
    },
    {
      title: "Spotha",
      links: ["Miskasa", "Agithe", "Scesha", "Lulle"],
    },
    {
      title: "Chashakib",
      links: ["Chogauw", "Phachuled", "Tiebeft", "Ocid", "Izom", "Ort"],
    },
    {
      title: "Athod",
      links: ["Pamuz", "Vapert", "Neesk", "Omemanen"],
    },
  ];

  return (
    <div className="footer" data-testid="Footer">
      {/* Bubble effects */}
      <div className="bubbles">
        {bubbles.map((bubble, index) => (
          <div
            key={index}
            className="bubble"
            style={{
              "--size": bubble.size,
              "--distance": bubble.distance,
              "--position": bubble.position,
              "--time": bubble.time,
              "--delay": bubble.delay,
            }}
          ></div>
        ))}
      </div>

      {/* Footer content */}
      <div className="content">
        <div>
          {footerLinks.map((section, index) => (
            <div key={index}>
              <b>{section.title}</b>
              {section.links.map((link, i) => (
                <a key={i} href="#">
                  {link}
                </a>
              ))}
            </div>
          ))}
        </div>

        {/* Footer logo & copyright */}
        <div>
          <a
            className="image"
            href="https://codepen.io/z-"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundImage: `url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/199011/happy.svg")`,
            }}
          />
          <p>©2019 Not Really</p>
        </div>
      </div>

      {/* Blob effect */}
      <svg style={{ position: "fixed", top: "100vh" }}>
        <defs>
          <filter id="blob">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="blob"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default Footer;
