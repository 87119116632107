export const BEBaseRoot = "http://127.0.0.1:8000/";
export const BEBase = BEBaseRoot + "api";

export const PaymentStatus = {
    SUCCESS: "Success",
    FAILURE: "Failure",
    PENDING: "Pending"
}

export const client_id =
  '621714949164-q18kqovvj4shnc4bnp23ru64m1vo2t5i.apps.googleusercontent.com';

export const project_id = 'tutorlix-413715';

export const auth_uri = 'https://accounts.google.com/o/oauth2/auth';

export const token_uri = 'https://oauth2.googleapis.com/token';

export const auth_provider_x509_cert_url =
  'https://www.googleapis.com/oauth2/v1/certs';

export const client_secret = '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';

export const wp_link = 'http://localhost:3000/api';
